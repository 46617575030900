










import SettingSubPageTemplate from "@/components/templates/SettingSubPageTemplate.vue";
import InformationList from "@/components/organisms/information/InformationList.vue";

export default {
  components: {
    SettingSubPageTemplate,
    InformationList,
  },
};
