















































































































































































import { Information } from "@/graphql/client";
import { Component, Ref, Emit, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import informationService from "@/service/informationService";
import { required, helpers } from "vuelidate/lib/validators";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import moment from "moment";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class InformationEditDialog extends Vue {
  @Ref() readonly informationForm!: HTMLFormElement;
  @Validations()
  validations = {
    item: {
      //タイトル
      title: { required },
      //本文
      content: { required },
    },
    publicationDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationEndDate", this, parentVm);
        return moment(value).isBefore(moment(targetDate));
      },
    },
    publicationEndDate: {
      validDate: (value: string, parentVm: Vue): boolean => {
        const targetDate = helpers.ref("publicationDate", this, parentVm);
        return moment(value).isAfter(moment(targetDate));
      },
    },
  };
  hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: Information = informationService.defaultInformation;
  publicationDayMenu = false;
  publicationDay = "";
  publicationTime = "";
  publicationEndDayMenu = false;
  publicationEndDay = "";
  publicationEndTime = "";
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationDate(): string {
    return moment(this.publicationDay + " " + this.publicationTime).format();
  }
  /**
   * 公開開始日の入力値から日時を取得
   */
  get publicationEndDate(): string {
    return moment(
      this.publicationEndDay + " " + this.publicationEndTime
    ).format();
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.item.createdAt
      ? moment(this.item.createdAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.item.updatedAt
      ? moment(this.item.updatedAt).format("YYYY-MM-DD HH:mm:ss")
      : "";
  }

  /**
   * タイトルの入力エラーメッセージを返します.
   */
  get titleErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.title?.$dirty) return errors;
    !this.$v.item.title?.required && errors.push("タイトルを入力してください.");
    return errors;
  }
  /**
   * 本文の入力エラーメッセージを返します.
   */
  get contentErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.content?.$dirty) return errors;
    !this.$v.item.content?.required && errors.push("本文を入力してください.");
    return errors;
  }
  /**
   * 公開開始日の入力エラーメッセージを返します.
   */
  get publicationDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationDate.$dirty) return errors;
    !this.$v.publicationDate.validDate &&
      errors.push("公開終了日より前の日時を指定してください.");
    return errors;
  }
  /**
   * 公開終了日の入力エラーメッセージを返します.
   */
  get publicationEndDateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.publicationEndDate.$dirty) return errors;
    !this.$v.publicationEndDate.validDate &&
      errors.push("公開開始日より後の日時を指定してください.");
    return errors;
  }
  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: Information): Promise<void> {
    this.item = informationService.defaultInformation;
    if (item.guid) {
      informationService.getInformation(item.guid).then((item) => {
        if (item) {
          this.item = item;
        }
      });
    }

    //フォームに表示するためにフォーマット変換
    this.publicationDay = moment(this.item.publicationDate).format(
      "YYYY-MM-DD"
    );
    this.publicationTime = moment(this.item.publicationDate).format(
      "HH:00"
    );
    this.publicationEndDay = moment(this.item.publicationEndDate).format(
      "YYYY-MM-DD"
    );
    this.publicationEndTime = moment(this.item.publicationEndDate).format(
      "HH:00"
    );
  }

  /**
   * ダイアログを表示します.
   */
  async open(item: Information): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      this.item.publicationDate = this.publicationDate;
      this.item.publicationEndDate = this.publicationEndDate;
      if (this.item.guid) {
        //update
        informationService
          .updateInformation(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as Information);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError();
          });
      } else {
        //create
        informationService
          .createInformation(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as Information);
          })
          .catch((e) => {
            console.log(e);
            this.notifyError();
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: Information): Information {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: Information): Information {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(): string {
    return "error!";
  }
}
