import { Information } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";
import moment from "moment";

/**
 * WEBセンターお知らせに関する機能を提供します.
 */
class InformationService {
  /**
   * 初期状態の Information
   */
  public get defaultInformation(): Information {
    return {
      guid: "",
      title: "",
      content: "",
      publicationDate: moment().format(),
      publicationEndDate: moment().add(7, "days").format(),
      createdAt: "",
      updatedAt: "",
    } as Information;
  }

  /**
   * すべての全体お知らせを取得して返します.
   * @returns すべての全体お知らせ
   */
  public async allInformations() {
    const response = await sdk.informations();
    if (response?.informations) {
      return response.informations as Information[];
    }
    return [] as Information[];
  }

  /**
   * 指定したGUIDに一致する全体お知らせを取得して返します.
   * @param guid 全体お知らせのGUID
   * @returns 全体お知らせ
   */
  public async getInformation(guid: string) {
    const response = await sdk.information({ guid: guid });
    return response.information as Information;
  }

  /**
   * 全体お知らせを登録します.
   * @param input 全体お知らせの登録情報
   * @returns 登録完了後の全体お知らせ
   */
  public async createInformation(input: Information) {
    try {
      const response = await sdk.createInformation({
        createInformationInput: {
          title: input.title,
          content: input.content,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
        },
      });

      if (response?.createInformation) {
        return response.createInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 全体お知らせを更新します.
   * @param input 全体お知らせの更新情報
   * @returns 更新完了後の全体お知らせ
   */
  public async updateInformation(input: Information) {
    try {
      const response = await sdk.updateInformation({
        updateInformationInput: {
          guid: input.guid,
          title: input.title,
          content: input.content,
          publicationDate: input.publicationDate,
          publicationEndDate: input.publicationEndDate,
        },
      });

      if (response?.updateInformation) {
        return response.updateInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 全体お知らせを削除します.
   * @param input 全体お知らせの削除情報
   * @returns 削除した全体お知らせ
   */
  public async deleteInformation(input: Information) {
    try {
      const response = await sdk.deleteInformation({
        deleteInformationInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteInformation) {
        return response.deleteInformation;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new InformationService();
